<template>
  <div>
    <TemplateBuilder></TemplateBuilder>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TemplateBuilder from "@/view/pages/templates/components/TemplateBuilder";

export default {
  name: "TemplateCreate",
  components: {
    TemplateBuilder
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Template Create" }]);

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },

  methods: {},
  computed: {
    ...mapGetters(["currentTemplate"])
  }
};
</script>
